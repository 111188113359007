<template>
  
  <div>REF#2</div>


</template>

<script>

import { mapActions } from 'vuex';
import { mapState }   from 'vuex';
import { mapGetters } from 'vuex';

import { EventBus } from '../EventBus';


export default {
    data: () => ({

      
    
    }),



  created(){

  

    },
 

  mounted() {

    
    
        },


  watch:{

   



  } ,


  computed : {

    

  },


  methods: {

    

     
  }

 }


</script>




