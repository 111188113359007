<template>
   <v-form ref="form" v-model="valid_form">
    
        
   </v-form>
</template>

<script>
  import { EventBus } from '../EventBus.js'
  export default {
     props: ['formParamsInput','accionInput'],
    data: () => ({

 

      
      valid_form:false,
    


      formParams: { 
           
          },

      dialog: false,
      
    }),

   



  

    
    methods: {
      
       clear () {
          //this.valid_form=true
           this.$refs.form.reset()
        },

      
      close () {
      console.log("entre para cerrar");
      this.clear();
      this.$emit("eventclose",false);
       
       
      },

      // agregarSexoNulo() {
      //   this.sexos.unshift({id=0, nombre=""})
      // },

    


    
     
    
    

    }
  }
</script>