<template >
<div>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    class="elevation-1"
  >
 
    <template  v-slot:top >
      <v-toolbar flat color="white">
        
       <caratula-form   :itemIndex="itemIndex"  :inputItem="editedItem" :actionItem="actionOnItem" ></caratula-form>
   
        
        <v-spacer></v-spacer>      
        </v-toolbar>
       <v-text-field v-model="search" label="buscar" class="mx-4"  prepend-icon="search"></v-text-field>
      
    </template>
       
    <template v-slot:item.action="{ item }">
     
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-on="on"
          >
            qr_code_2
          </v-icon>
       </template>   
      <span>Generar</span>
    </v-tooltip>
    
     
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="fetchItems">Recargar</v-btn>
    </template>
  </v-data-table>

</div>

</template>

<script>


import { EventBus } from "../../EventBus"
import CaratulaForm from "./CaratulaForm"

  export default {

   components:{'caratula-form':CaratulaForm},


   


    data: () => ({
      
      items:[],
      search: '',
      openForm: false,
     
    
      headers: [
       
        { text: 'Siglas', value: 'siglas' },
        { text: 'Columna', value: 'columna' },
        { text: 'Fila', value: 'fila' },
        { text: 'Caja', value: 'caja' },
        { text: 'Ubicación', value: 'ubicacion_expediente' },
        { text: 'Generar Carátula', value: 'action', sortable: false }
        
      ],
      itemIndex: -1,
      actionOnItem:'',
      editedItem: {}
    }),

    created(){

      this.fetchItems();

    },

    mounted(){

      EventBus.$on('items_refreshed', (items) => {
        this.items = items;
        
      });
    

    },


   


    methods: {



      fetchItems(){

       
        
           
            axios.get('api/listar_expedientes_caratula')
           .then(response=>{    
            
                  this.items=response.data
               })
              .catch((error)=>{
                    console.log(error);

              })
       },

       editItem (item) {

          

          this.actionOnItem='edit';
          this.itemIndex = this.items.indexOf(item);
          this.editedItem = Object.assign({}, item); 
          this.openForm=true; 
       
        },

       

   


    }

      
    
  }
</script>