<template>
  
  <div>

  <expedienteList></expedienteList>
  

</div>
</template>

<script>



import ExpedienteList from '../components/Expediente/ExpedienteList';


export default {

    components:{ExpedienteList},

   

 }


</script>




