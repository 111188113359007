<template>

  <v-app>  

    <v-dialog v-model="login_dialog" max-width="500px">             
               <login @eventclose="login_dialog=$event"></login>
    </v-dialog>

    <v-dialog v-model="register_dialog" max-width="500px">             
               <register @eventclose="register_dialog=$event"></register>
    </v-dialog>

    <v-navigation-drawer  v-if="$auth.isLoggedIn()"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app

    >
      <v-list dense nav>
        <template v-for="item in items">

          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.icon"
            :append-icon="item['icon-alt']"
          >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                :to="{name:child.route}"
                link
              >
                  <v-list-item-action v-if="child.icon">
                      <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title>
                        {{ child.text }}
                      </v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
          </v-list-group>
            <v-list-item
                v-else
                :key="item.text"
                :to="{name:item.route}"
                link
            >
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4"
      >
        <span class="hidden-sm-and-down">{{app_name}}</span>
      </v-toolbar-title>
      
      <v-spacer />

       <v-toolbar-items v-if="!$auth.isLoggedIn()">

            <v-btn  @click="login_dialog=true" text >Ingresar</v-btn>
            <!-- <v-btn  @click="register_dialog=true" text >Sign in</v-btn>         -->   
          </v-toolbar-items>

       <v-toolbar-items v-else>
             
            <v-btn  text v-if="$auth.getUser()">{{$auth.getUser().name}}</v-btn>

            <v-btn icon @click="$auth.logout()">
              <v-icon>logout</v-icon>
            </v-btn>
                     
          </v-toolbar-items>
    </v-app-bar>
   
               
      <v-main>
       <v-container fluid>
        <v-row
          justify="center"
          align="center"
        >
       
            <router-view></router-view>
            <loader></loader> 
           
         </v-row>
      </v-container>
    </v-main>            

      <v-footer app absolute>
            <v-col
            class="text-center"
            cols="12"
          >
            {{ new Date().getFullYear() }} — <strong>{{app_name}}</strong> - Compilación 20 de agosto de 2024
          </v-col>
      </v-footer>

  </v-app>   

  
</template>
<script>
import Loader  from './partials/_loader'
import Login from './Login';
import Register from './Register';
import { EventBus } from '../EventBus';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';


export default {
    
  data:() => ({

    app_name: process.env.MIX_APP_NAME,
    

    logged_user:null,
    login_dialog:false,
    register_dialog:false,
    drawer: true,
    items: [
       // { icon: 'list_alt', text: 'Solicitud' , route:'Solicitud'},
      { icon: 'create_new_folder', text: 'Expediente' , route:'Expediente'},
      { icon: 'qr_code', text: 'Generar Carátula' , route:'Caratula'},
      { icon: 'topic', text: 'Gestión Desarchivo' , route:'Gestion-Desarchivo'},
      { icon: 'drive_file_move', text: 'Solicitud Desarchivo' , route:'Solicitud-Desarchivo'},
     /*    { icon: 'group', text: 'Archivo' , route:'Archivo'}, */
       // { icon: 'group', text: 'paises' , route:'Paises'},
        
        
        {
          icon: 'settings',
          'icon-alt': 'keyboard_arrow_down',
          'icon-up': 'keyboard_arrow_up',
          text: 'Configuración',
          model: false,
          children: [

            { icon: 'corporate_fare',text: 'Estantes' , route:'Estantes'},
            { icon: 'person_add',text: 'Parte' , route:'Parte'}
           
            
          ]
        }
        
      ],
        
    }),

  components:{

          'loader':Loader,

          'login': Login,
          'register': Register
    },

     created () {

            axios.interceptors.request.use( (config) => {
              // Do something before request is sent
              this.$store.commit('setLoader',true);
              return config;
            },  (error) => {
              // Do something with request error
               this.$store.commit('setLoader',false);
              return Promise.reject(error);
            });

          // Add a response interceptor
          axios.interceptors.response.use( (response) =>{
              // Do something with response data
              this.$store.commit('setLoader',false);
              return response;
            },  (error)=> {
              // Do something with response error
              this.$store.commit('setLoader',false);
              return Promise.reject(error);
            });
  
      },




  mounted(){

    EventBus.$on('user_logged_in', () => {
        this.login_dialog=false;
      });

    EventBus.$on('user_registered', () => {
        this.register_dialog=false;
      });


  },

  computed : {

     ...mapGetters([
      
    ])


  },


  methods: {


     }
};


</script>       