<template >
  
  <div>  <v-card>
    
    <v-card-title>
      USUARIOS
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    
    <v-data-table
      :headers="headers"
      :items="usuarios.data"
      :search="search"
      hide-default-footer
    ></v-data-table>
     <div class="text-center pt-2">
      <v-pagination v-model="current_page" :length="pageCount" :total-visible="7"></v-pagination>
      </div>
           
     
      



  </v-card></div>


</template>

<script>


  export default {
   

    data () {

      
      return {
        search: '',
        headers: [
          
          { text: 'name', value: 'name' },
          { text: 'Email', value: 'email' },
         
        ],
       
      dialog: false,
      usuarios:{},
      formParams:[],
      accion:'Crear',
      }
    },

      created() {

    this.cargar_usuarios(1)
    
        },

        watch:{

            current_page(new_value,old_value)
            {
              console.log("este es dentro de wath="+new_value);
              this.cargar_usuarios(new_value);
              
            }

          },

        computed : {

          current_page:{

            get(){
              console.log("usurios current page="+this.usuarios.current_page);
              return this.usuarios.current_page;

              
            },
            set(value){
              console.log("este es el valor en set="+value);

              this.usuarios.current_page=value;
            }
          },

          pageCount:{

            get(){
              return this.usuarios.last_page;
              
            }
          },

          

        },

    methods: {

            crearUsuario () {
            console.log("entre función");
            this.formParams = { }
            this.accion='Crear';
            this.dialog = true;
       
         
        } ,
        close () {
        this.dialog = false
        
        },

          }

        
  }
</script>




