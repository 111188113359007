<template>
  <div>
    <v-form ref="form" v-model="valid_form" lazy-validation>
     <v-dialog v-model="openDialog" max-width="900px" scrollable>

      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>

          <v-container grid-list-md>
            <v-layout wrap>
            <!-- Campos del Modal de Crear Solicitud de Desarchivo -->
              <v-flex xs12 md6>
                  <estado-solicitud :inputItem="formParams" ></estado-solicitud>
                <v-select
                  :items="estadosSolicitud"
                  v-model="formParams.estado_solicitud"
                  label="Cambiar el estado de la solicitud"
                  prepend-icon="state"
                  :rules="[rules.required]"
                  item-text="text"
                  item-value="id"
                ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                  <estado-desarchivo :inputItem="formParams" ></estado-desarchivo>
                <v-select
                  :items="estadosDesarchivo"
                  v-model="formParams.estado_desarchivo"
                  label="Cambiar el estado del desarchivo"
                  prepend-icon="state"
                  :rules="[rules.required]"
                  item-text="text"
                  item-value="id"
                ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="formParams.fecha_estado_desarchivo"
                    label="Fecha Estado Desarchivo"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="formParams.fecha_estado_desarchivo"
                    :active-picker.sync="activePicker"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="saveDate"
                ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md6>
                  <v-text-field
                    label="URL del archivo o soporte relacionado con la gestión"
                    v-model="formParams.url_adjunto_gestion"
                    prepend-icon="mdi-attachment"
                    ></v-text-field>
            </v-flex>

            <v-flex xs12 md6>

                    <v-switch
                    v-model="entrega_digital"
                    :label="`Entrega en digital: ${entrega_digital?'Si':'No'}`"
                    ></v-switch>

                    <v-text-field
                    v-if="entrega_digital"
                    label="URL del expediente en digital"
                    v-model="formParams.url_expediente_digital"
                    prepend-icon="mdi-folder-download"
                    ></v-text-field>

                    <v-text-field
                    v-if="!entrega_digital"
                    v-model="formParams.receptor"
                    label="Nombre de quién recibe el expediente"
                    prepend-icon="mdi-account-arrow-left"
                    type="text"
                    >
                    </v-text-field>

                    <v-text-field
                    v-if="!entrega_digital"
                    label="URL del acta de recibido"
                    v-model="formParams.acta_recibido"
                    prepend-icon="mdi-file-document-outline"
                    ></v-text-field>
            </v-flex>

              <v-flex xs12 md6>
                <v-textarea
                    filled
                    label="Comentario de la gestión"
                    name="observacion_gestion"
                    v-model="formParams.observacion_gestion"
                    ></v-textarea>
              </v-flex>

            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn color="pink"  dark  @click.native="clear">Limpiar</v-btn>
           <v-btn color="blue"  dark  @click.native="close">Cancelar</v-btn>
           <v-btn color="green" dark  :disabled="!valid_form" @click.native="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>

  </div>
</template>


<script>

  import { EventBus } from "../../EventBus"
  import EstadoSolicitud from "./EstadoSolicitud"
  import EstadoDesarchivo from "./EstadoDesarchivo"

  export default {

    components:{
        'estado-solicitud':EstadoSolicitud,
        'estado-desarchivo':EstadoDesarchivo
    },

    props:[

          'itemIndex',
          'inputItem',
          'actionItem',
          'row_id',
    ],

  data: () => ({

      rules: {
          required: value => !!value || 'Campo requerido',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
      activePicker: null,
      date: null,
      menu: false,
      entrega_digital: false,

      valid_form:true,
      openDialog:false,
      actionOnItem:'',

      formParams: {},

      estadosSolicitud: [
        { text: 'Pendiente', id: '1' },
        { text: 'En trámite', id: '2' },
        { text: 'Realizada', id: '3' }
      ],

      estadosDesarchivo: [

        { text: 'En Búsqueda', id: '1' },
        { text: 'Paquete Errado', id: '2' },
        { text: 'En Oficina Judicial', id: '3' },
        { text: 'En Juzgado', id: '4' },
        { text: 'Devuelto a Archivo', id: '5' },
      ],
      usuarios: [

      ],
      title:'Gestión de Solicitud de Desarchivo'
  }),


  computed: {

      formTitle () {

        /* if(this.actionOnItem == 'create')
        {
          this.clear();
        } */

        return this.actionOnItem == 'create' ? ('Crear ' + this.title) : ('Editar ' + this.title)
      },
  },

   created () {

   },

   watch: {

       menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },

      inputItem: function(val) {

        if (Object.keys(val).length){

          this.formParams=Object.assign({}, val);
          this.formParams.url_expediente_digital?this.entrega_digital=true:this.entrega_digital=false
        //   console.log(this.formParams)
          this.actionOnItem='edit';
          this.openDialog=true;
        }
      }
    },

    methods: {

      saveDate (date) {
        this.$refs.menu.save(date)
      },

        save(){
            if(this.entrega_digital){
                this.formParams.receptor = null
                this.formParams.acta_recibido = null
            } else {
                this.formParams.url_expediente_digital = null
            }

           if (this.$refs.form.validate()) {
               axios.put('api/solicitud_desarchivo/'+this.formParams.id,this.formParams)
                .then(response=>{
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido actualizado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close();
            location.reload();
        },

        close () {

          this.openDialog = false;

         },

        clear () {
          this.$refs.form.reset();
        },
    }
  }
</script>
