<template >
<div>

  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    class="elevation-1"
  >

    <template  v-slot:top >
      <v-toolbar flat color="white">
        <gestion-desarchivo-form   :itemIndex="itemIndex"  :inputItem="editedItem" :actionItem="actionOnItem" ></gestion-desarchivo-form>
        <v-spacer></v-spacer>
        </v-toolbar>
    </template>

    <template v-slot:item.estado_solicitud="{ item }">

        <estado-solicitud :inputItem="item" ></estado-solicitud>

    </template>

    <template v-slot:item.estado_desarchivo="{ item }">

        <estado-desarchivo :inputItem="item" ></estado-desarchivo>

    </template>

    <template v-slot:item.action="{ item }">

        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon
                    small
                    @click="verItem(item)"
                    v-on="on"
                >
                    mdi-eye
                </v-icon>
            </template>
            <span>Detalles</span>
        </v-tooltip>

        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    v-on="on"
                >
                    mdi-pencil
                </v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon
                    small
                    @click="comentarItem(item)"
                    v-on="on"
                >
                    mdi-chat
                </v-icon>
            </template>
            <span>Comentarios</span>
        </v-tooltip>

    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="fetchSolicitudDesarchivo">Recargar</v-btn>
    </template>
  </v-data-table>

    <detalles :inputItem="detallesItem.id" ></detalles>
    <comentarios :inputItem="detallesItemC" ></comentarios>


</div>

</template>

<script>

import GestionDesarchivoForm from "./GestionDesarchivoForm"
import Detalles from "./Detalles"
import Comentarios from "../Comentarios/ComentariosList"
import EstadoSolicitud from "./EstadoSolicitud"
import EstadoDesarchivo from "./EstadoDesarchivo"
import { EventBus } from "../../EventBus"

  export default {

    components:{
        'gestion-desarchivo-form':GestionDesarchivoForm,
        'detalles':Detalles,
        'comentarios':Comentarios,
        'estado-solicitud':EstadoSolicitud,
        'estado-desarchivo':EstadoDesarchivo
    },

    data: () => ({

      items:[],
      search: '',
      openForm: false,
      tipo: '',
      buscar: '',

      headers: [

        { text: 'Nro. Solicitud', value: 'id' },
        { text: 'Estado Solicitud', value: 'estado_solicitud' },
        { text: 'Estado Desarchivo', value: 'estado_desarchivo' },
        { text: 'Radicado', value: 'radicado' },
        { text: 'Acciones', value: 'action', sortable: false }
      ],
      itemIndex: -1,
      actionOnItem:'',
      editedItem: {},
      detallesItem: {},
      detallesItemC: {}
    }),

    created(){

       this.fetchSolicitudDesarchivo();
    },
    /*mounted(){

      EventBus.$on('items_refreshed', (items) => {
        this.items = items;

      });


    },*/
    computed: {
        axiosParams() {
            const params = new URLSearchParams();
            params.append('consulta', 'gestor');
            return params;
        }
    },

    methods: {
      fetchSolicitudDesarchivo(){
         axios.get('api/solicitud_desarchivo', {
                params: this.axiosParams
            })
              .then(response=>{
                  this.items=response.data.data
                //   console.log(this.items)
               })
              .catch((error)=>{
                    console.log(error);

              })
       },
       verItem(item){
           this.detallesItem = Object.assign({}, item);
       },
       comentarItem(item) {
           this.detallesItemC = Object.assign({}, item);
        },
         editItem (item) {
          this.actionOnItem='edit';
          this.itemIndex = this.items.indexOf(item);
          this.editedItem = Object.assign({}, item);
          this.openForm=true;
        },
    }
  }
</script>
