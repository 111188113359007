<template>
  
  <div>

  <estanteList></estanteList>
  

</div>
</template>

<script>



import EstanteList from '../components/Parametrizacion/EstanteList';


export default {

    components:{EstanteList},

   

 }


</script>




