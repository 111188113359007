<template>
  
  <div>

  <expedienteCaratula></expedienteCaratula>
  

</div>
</template>

<script>



import ExpedienteCaratula from '../components/Expediente/ExpedienteCaratula';


export default {

    components:{ExpedienteCaratula},

   

 }


</script>




