<template>
  
  <div>

  <ArchivoListar></ArchivoListar>
  

</div>
</template>

<script>



import ArchivoListar from '../components/Archivo/ArchivoListar';


export default {

    components:{ArchivoListar},

   

 }


</script>




