var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('expediente-form',{attrs:{"itemIndex":_vm.itemIndex,"inputItem":_vm.editedItem,"actionItem":_vm.actionOnItem}}),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),_c('v-container',[_c('v-layout',[_c('v-flex',{attrs:{"xs3":"","md3":""}},[_c('v-select',{attrs:{"items":_vm.tipos,"label":"Buscar por:","prepend-icon":"type"},model:{value:(_vm.tipo),callback:function ($$v) {_vm.tipo=$$v},expression:"tipo"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs3":"","md3":""}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Texto a buscar"},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs2":"","md2":""}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":"","disabled":_vm.tipo&&!_vm.buscar.length},on:{"click":function($event){return _vm.fetchExpedientes()}}},[_vm._v("\n                        Buscar\n                        "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v("\n                        search\n                        ")])],1)],1),_vm._v(" "),_c('v-flex',{attrs:{"xs3":"","md3":""}},[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length),expression:"items.length"}],staticClass:"mx-4",attrs:{"label":"filtrar en los resultados","prepend-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.estado_prestamo",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.estado_prestamo==1)?_c('span',[_vm._v("Archivado")]):_vm._e(),_vm._v(" "),(item.estado_prestamo==2)?_c('a',{on:{"click":function($event){return _vm.verItem(item.solicitud_desarchivo_id)}}},[_vm._v("En préstamo")]):_vm._e(),_vm._v(" "),(item.estado_prestamo==3)?_c('a',{on:{"click":function($event){return _vm.verItem(item.solicitud_desarchivo_id)}}},[_vm._v("Devuelto")]):_vm._e()]}}],null,true)})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("\n            mdi-pencil\n          ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editar")])]),_vm._v(" "),(item.url_expediente_digital)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":item.url_expediente_digital,"target":"_blank"}},[_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("\n        mdi-folder-download\n      ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Digital")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetchExpedientes}},[_vm._v("Recargar")])]},proxy:true}])}),_vm._v(" "),_c('detalles',{attrs:{"inputItem":_vm.detallesItemId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }