var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('solicitud-desarchivo-form',{attrs:{"itemIndex":_vm.itemIndex,"inputItem":_vm.editedItem,"actionItem":_vm.actionOnItem}}),_vm._v(" "),_c('v-spacer')],1)]},proxy:true},{key:"item.estado_solicitud",fn:function(ref){
var item = ref.item;
return [_c('estado-solicitud',{attrs:{"inputItem":item}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.verItem(item)}}},on),[_vm._v("\n                    mdi-eye\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Detalles")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("\n            mdi-pencil\n          ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editar")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.comentarItem(item)}}},on),[_vm._v("\n        mdi-chat\n      ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Comentarios")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetchSolicitudDesarchivo}},[_vm._v("Recargar")])]},proxy:true}])}),_vm._v(" "),_c('detalles',{attrs:{"inputItem":_vm.detallesItem.id}}),_vm._v(" "),_c('comentarios',{attrs:{"inputItem":_vm.detallesItemC}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }