<template>

  <div>

  <gestionDesarchivoList></gestionDesarchivoList>


</div>
</template>

<script>



import gestionDesarchivoList from '../components/GestionDesarchivo/GestionDesarchivoList';


export default {

    components:{gestionDesarchivoList},



 }


</script>




