<template>
<div>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <!-- <component :is="formComponentName"  v-bind="formProps"  ></component> -->


        <v-spacer></v-spacer>
        </v-toolbar>
       <v-text-field v-model="search" label="buscar" class="mx-4"  prepend-icon="search"></v-text-field>

    </template>


    <template v-slot:no-data>
      <v-btn color="primary" @click="fetchItems">Recargar</v-btn>
    </template>
  </v-data-table>

</div>

</template>

<script>

import ArchivoForm from "./ArchivoForm"
import { EventBus } from "../../EventBus"

  export default {


    components:{'archivo-form':ArchivoForm},


    data: () => ({

      items:[],
      search: '',
      openForm: false,
      headers: [

        { text: 'Ubicación', value: 'ubicacion' },
        { text: 'Radicado', value: 'radicado' },
        { text: 'Despacho', value: 'juzgado.nombre' },
        { text: 'Demandante Nombre', value: 'demandante.nombres' },
        { text: 'Demandante Apellido', value: 'demandante.apellidos' },
        { text: 'Demandado Nombre', value: 'demandado.nombres' },
        { text: 'Demandado Apellido', value: 'demandado.apellidos' },

      ],
      itemIndex: -1,
      actionOnItem:'',
      editedItem: {}
    }),

    created(){

      this.fetchItems();


    },

    mounted(){

      EventBus.$on('items_refreshed', (items) => {
        this.items = items;

      });


    },





    methods: {



      fetchItems(){

          //console.log("hola esta es la url:"+process.env.APP_URL2);
          // console.log("location:")
          // console.log(window.location)
          // console.log("origen:")
          // console.log(window.location.origin)

             //axios.get('http://inventariomz.archivosinnsoftech.com/api/listar_expedientes_asociados_caratula/'+this.$route.params.id+'/listar_expedientes_asociados_caratula')
            axios.get(window.location.origin+'/api/listar_expedientes_asociados_caratula/'+this.$route.params.id+'/listar_expedientes_asociados_caratula')
            //axios.get('api/listar_expedientes_asociados_caratula/'+this.$route.params.id+'/listar_expedientes_asociados_caratula')
           // axios.get('http://127.0.0.1:8000/api/listar_expedientes_asociados_caratula/'+this.$route.params.id+'/listar_expedientes_asociados_caratula')
           // axios.get('api/listar_estantes_activos')
              .then(response=>{

                  this.items=response.data
                  // alert(JSON.stringify(this.items));


              })
              .catch((error)=>{
              console.log(error);
              });


       },

       deleteItem(item) {


        this.$alertify.confirmWithTitle("Borrar registro", "Está seguro de eliminar este registro?", ()=>{
            axios.delete('api/archivo/'+item.id)
                      .then(response=>{
                        this.items=response.data.data;
                        this.$alertify.error("Registro ha sido eliminado!");
                      })
                      .catch((error)=>{
                    console.log(error);

                   });

                      },() =>this.$alertify.error("Acción cancelada!"))


        },

         editItem (item) {

          this.actionOnItem='edit';
          this.itemIndex = this.items.indexOf(item);
          this.editedItem = Object.assign({}, item);
          this.openForm=true;

        },





    }



  }
</script>
