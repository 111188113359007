<template>

    <v-flex xs12 md12>
        <v-card
            class="mx-auto"
            outlined
        >
            <v-list-item three-line>

                <v-list-item-avatar color="red">
                    <span class="white--text text-h5">{{sigla(inputItem.usuario.name)}}</span>
                </v-list-item-avatar>

                <v-list-item-content>
                    <div class="text-overline mb-4">
                    {{inputItem.usuario.name}} <small>{{inputItem.created_at}}</small>
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                    </v-list-item-title>
                    <v-list-item-subtitle>{{inputItem.comentario}}</v-list-item-subtitle>
                </v-list-item-content>

            </v-list-item>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    rounded
                    text
                    small
                    @click.native="formResponder=!formResponder"
                >
                    Responder
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="formResponder">
            <v-card-title>
            <span class="headline"></span>
            </v-card-title>
            <v-card-text>
                <v-textarea
                    filled
                    label="Agregue la respuesta"
                    name="comentario"
                    v-model="formParams.comentario"
                    ></v-textarea>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue"  dark  @click.native="formResponder = false">Cancelar</v-btn>
            <v-btn color="pink"  dark  @click.native="formParams.comentario=''">Limpiar</v-btn>
            <v-btn color="green" dark  @click.native="responder">Responder</v-btn>
            </v-card-actions>
        </v-card>

        <template v-for="(item, index) in items">
            <comentario :inputItem="item" class="sub"></comentario>
        </template>

    </v-flex>

</template>

<script>

export default {

    components:{
        'comentario': () => import('./Comentario.vue'),
    },

    props:[
        'inputItem'
    ],

    data: () => ({

      items:[],
      openDialog: false,

      itemIndex: -1,
      actionOnItem:'',
      formParams: {},
      formResponder: false
    }),

    mounted(){

     this.fetchComentarios()

    },

    computed: {
        axiosParams() {
            const params = new URLSearchParams();
            params.append('solicitud_desarchivo_id', this.inputItem.solicitud_desarchivo_id);
            params.append('en_respuesta_id', this.inputItem.id);
            return params;
        }
    },

    methods: {
        sigla(name) {
            name = name + ''
            let siglas = name.split(' ');
            return siglas[0].charAt(0)+(siglas[1]?siglas[1].charAt(0):'')
        },
        fetchComentarios(){
          axios.get('api/comentario', {
                params: this.axiosParams
            })
              .then(response=>{
                  this.items=response.data.data
               })
              .catch((error)=>{
                    console.log(error);

              })
       },
       responder(){
          if (this.formParams.comentario) {
              this.formParams.solicitud_desarchivo_id = this.inputItem.solicitud_desarchivo_id
              this.formParams.en_respuesta_id = this.inputItem.id
              axios.post('api/comentario',this.formParams)
                .then(response=>{

                    this.fetchComentarios();
                    this.$alertify.success("¡Se ha enviado la respuesta!")
                    this.formParams.comentario = ''
                    this.formResponder = false
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             this.$alertify.message("Debe ingresar una respuesta")
           }
        },
    }
}
</script>

<style>
.sub{
    width: 90%;
    float: right;
}
</style>

