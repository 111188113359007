<template>
  
  <div>
              <v-img :aspect-ratio="10/9" src="img/logo-innsoftech.png" width="500">
              <v-row align="end" class="lightbox white--text pa-2 fill-height">         
              </v-row>
              </v-img>


  </div>


</template>

<script>

import { mapActions } from 'vuex';
import { mapState }   from 'vuex';
import { mapGetters } from 'vuex';

import { EventBus } from '../EventBus';


export default {
    data: () => ({

      
    
    }),



  created(){

  

    },
 

  mounted() {

    
    
        },


  watch:{

   



  } ,


  computed : {

    

  },


  methods: {

    

     
  }

 }


</script>




