<template>
  <div>
    <v-form ref="form" v-model="valid_form" lazy-validation> 
     <v-dialog v-model="openDialog" max-width="900px" scrollable>

       <template v-slot:activator="{ on }">
          <v-btn
             v-if="icon"
             fab
             x-small
             slot="activator"
             color="primary" dark
             @click="setModalCreate" 
             v-on="on"
          >
            <v-icon>add</v-icon>
          </v-btn>

        <v-btn v-else slot="activator" color="primary" dark   @click="setModalCreate" v-on="on">Crear {{title}}</v-btn>
          
      </template>  
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
            <!-- Campos del Modal de Crear Estante -->
              <v-flex xs12 md6>
                <v-select
                  :items="bodegas"
                  v-model="formParams.bodega_id"
                  label="Bodega"
                  prepend-icon="store"
                  item-text="nombre"
                  item-value="id"
                  :rules="[rules.required]"
                  return-value
                ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.descripcion" 
                    label="Descripción" 
                    prepend-icon="short_text"  
                    maxlength="100"
                    :rules="[rules.required]"
                    >       
                </v-text-field>
              </v-flex>  
              <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.capacidad" 
                    label="Capacidad" 
                    prepend-icon="check_box_outline_blank"  
                    maxlength="3"
                    :rules="[rules.required]"
                >       
                </v-text-field>
              </v-flex>  
              <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.filas" 
                    label="Filas" 
                    prepend-icon="table_rows"  
                    maxlength="3"
                    :rules="[rules.required]"
                    >       
                </v-text-field>
              </v-flex> 
              <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.columnas" 
                    label="Columnas" 
                    prepend-icon="view_column"  
                    maxlength="3"
                    :rules="[rules.required]"
                    >       
                </v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.cajasxpos" 
                    label="Cajas x Posición" 
                    prepend-icon="all_inbox"  
                    maxlength="3"
                    :rules="[rules.required]"
                    >       
                </v-text-field>
              </v-flex> 
              <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.ubicacion" 
                    label="Ubicación" 
                    prepend-icon="location_on"  
                    maxlength="200"
                    :rules="[rules.required]"
                    >       
                </v-text-field>
              </v-flex>        

            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn color="pink"  dark  @click.native="clear">Limpiar</v-btn>
           <v-btn color="blue"  dark  @click.native="close">Cancelar</v-btn>
           <v-btn color="green" dark  :disabled="!valid_form" @click.native="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>

  </div>
</template>


<script>
 
  import { EventBus } from "../../EventBus"
  export default {

    props:[
          
          'icon',  
          'itemIndex',
          'inputItem',
          'actionItem'
    ],  

  data: () => ({
      
      
      bodegas:[], 
      valid_form:true,
      openDialog:false, 
      editedItem: {},
      actionOnItem:'',
       formParams: { 
           
          },
      defaultItem: {
            name: ''  
      }, 
      title:'Estante',

       rules: {
          required: value => !!value || 'Campo requerido',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },

       
     
  }),

  
  computed: {

      formTitle () {
        return this.actionOnItem == 'create' ? ('Crear ' + this.title) : ('Editar ' + this.title)
      },

  },

   created () {

     this.listar_bodegas_activas();

   }, 

   watch: {
      
      inputItem: function(val) {

        if (Object.keys(val).length){

          this.editedItem=Object.assign({}, val);
          this.actionOnItem='edit';
          this.openDialog=true;
        }
      },

       'formParams.capacidad': function (val){
         this.formParams.utilizado = 0;
         this.formParams.disponible = val;
       },

      
    },


  methods: {


        createItem(){

         

          if (this.$refs.form.validate()) {

              axios.post('api/estante',this.formParams)
                .then(response=>{
                    
                    
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido agregado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close() 

        },

        updateItem(){

           if (this.$refs.form.validate()) {

              axios.put('api/estante/'+this.editedItem.id,this.editedItem)
                .then(response=>{
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido actualizado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close();

        },

        save(){

          if (this.actionOnItem =='create') {
              this.createItem();
             }
          else {
             this.updateItem();
           }
        },

        setModalCreate(){

          this.actionOnItem='create';
          this.editedItem = Object.assign({}, this.defaultItem);
        },

        close () {

          this.openDialog = false;

         },

        clear () {
          this.$refs.form.reset();
        },


     




    }



    
  
   
  
  }
</script>
