<template>
<div>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <!-- <component :is="formComponentName"  v-bind="formProps"  ></component> -->

        <estante-form   :itemIndex="itemIndex"  :inputItem="editedItem" :actionItem="actionOnItem" ></estante-form>
        <v-spacer></v-spacer>      
        </v-toolbar>
       <v-text-field v-model="search" label="buscar" class="mx-4"  prepend-icon="search"></v-text-field>
      
    </template>
       
    <!-- <template v-slot:item.action="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-on="on"
          >
            mdi-pencil
          </v-icon>
       </template>   
      <span>Editar</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
      <v-icon
        small
        @click="deleteItem(item)"
        v-on="on"
      >
        mdi-delete
      </v-icon>
    </template>
      <span>Eliminar</span>
    </v-tooltip>
    </template> -->

    <template v-slot:no-data>
      <v-btn color="primary" @click="fetchItems">Recargar</v-btn>
    </template>
  </v-data-table>

</div>

</template>

<script>

import EstanteForm from "./EstanteForm"
import { EventBus } from "../../EventBus"

  export default {


    components:{'estante-form':EstanteForm},


    data: () => ({
      
      items:[],
      search: '',
      openForm: false,
      
      headers: [
       
        { text: 'Bodega', value: 'bodega.nombre' },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Capacidad', value: 'capacidad' },
        { text: 'Utilizado', value: 'utilizado' },
        { text: 'Disponible', value: 'disponible' },
        { text: 'Filas', value: 'filas' },
        { text: 'Columnas', value: 'columnas' },
        { text: 'Cajas x Posición', value: 'cajasxpos' },
        /* { text: 'Ubicación', value: 'ubicacion' }, */
        /* { text: 'Acciones', value: 'action', sortable: false } */
      ],
      itemIndex: -1,
      actionOnItem:'',
      editedItem: {}
    }),

    created(){

      this.fetchItems();

    },

    mounted(){

      EventBus.$on('items_refreshed', (items) => {
        this.items = items;
        
      });
    

    },


   


    methods: {



      fetchItems(){

       
         axios.get('api/estante')
              .then(response=>{
                  this.items=response.data.data
               })
              .catch((error)=>{
                    console.log(error);

              })
       },

       deleteItem(item) {


        this.$alertify.confirmWithTitle("Borrar registro", "Está seguro de eliminar este registro?", ()=>{
            axios.delete('api/country/'+item.id)
                      .then(response=>{
                        this.items=response.data.data;
                        this.$alertify.error("Registro ha sido eliminado!");
                      })
                      .catch((error)=>{
                    console.log(error);

                   });

                      },() =>this.$alertify.error("Acción cancelada!"))


        },

         editItem (item) {

           alert(item);

          this.actionOnItem='edit';
          this.itemIndex = this.items.indexOf(item);
          this.editedItem = Object.assign({}, item); 
          this.openForm=true;
       
        },


   


    }

      
    
  }
</script>