<template >
<div>

  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    class="elevation-1"
  >

    <template  v-slot:top >
      <v-toolbar flat color="white">
        <!-- <component :is="formComponentName"  v-bind="formProps"  ></component> -->

        <expediente-form   :itemIndex="itemIndex"  :inputItem="editedItem" :actionItem="actionOnItem" ></expediente-form>
        <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>
            <v-layout>
                <v-flex xs3 md3>
                    <v-select
                        :items="tipos"
                        v-model="tipo"
                        label="Buscar por:"
                        prepend-icon="type"
                    ></v-select>
                </v-flex>
                <v-flex xs3 md3>
                    <v-text-field v-model="buscar" label="Texto a buscar" class="mx-4"></v-text-field>
                </v-flex>
                <v-flex xs2 md2>
                    <v-btn
                        class="ma-2"
                        color="primary"
                        dark
                        @click="fetchExpedientes()"
                        :disabled="tipo&&!buscar.length"
                    >
                        Buscar
                        <v-icon
                        dark
                        right
                        >
                        search
                        </v-icon>
                    </v-btn>
                </v-flex>
                <v-flex xs3 md3>
                    <v-text-field v-show="items.length" v-model="search" label="filtrar en los resultados" class="mx-4"  prepend-icon="search"></v-text-field>
                </v-flex>
            </v-layout>
        </v-container>


    </template>

    <template v-slot:item.estado_prestamo="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <span
            v-if="item.estado_prestamo==1"
            >Archivado</span>
            <a
            v-if="item.estado_prestamo==2"
            @click="verItem(item.solicitud_desarchivo_id)"
            >En préstamo</a>
            <a
            v-if="item.estado_prestamo==3"
            @click="verItem(item.solicitud_desarchivo_id)"
            >Devuelto</a>
       </template>
       </v-tooltip>
    </template>

    <template v-slot:item.action="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-on="on"
          >
            mdi-pencil
          </v-icon>
       </template>
      <span>Editar</span>
    </v-tooltip>
     <!--
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
      <v-icon
        small
        @click="deleteItem(item)"
        v-on="on"
      >
        mdi-delete
      </v-icon>
    </template>
      <span>Eliminar</span>
    </v-tooltip>
    -->

    <v-tooltip bottom v-if="item.url_expediente_digital">
      <template v-slot:activator="{ on }">
        <a :href="item.url_expediente_digital" target="_blank">
      <v-icon
        small

        v-on="on"
      >
        mdi-folder-download
      </v-icon>
      </a>
    </template>
      <span>Digital</span>
    </v-tooltip>

    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="fetchExpedientes">Recargar</v-btn>
    </template>
  </v-data-table>

  <detalles :inputItem="detallesItemId" ></detalles>

</div>

</template>

<script>

import ExpedienteForm from "./ExpedienteForm"
import Detalles from "../GestionDesarchivo/Detalles"
import { EventBus } from "../../EventBus"

  export default {


    components:{
        'expediente-form':ExpedienteForm,
        'detalles':Detalles
    },


    data: () => ({

      items:[],
      search: '',
      openForm: false,
      tipo: '',
      buscar: '',

      tipos: [
        { text: 'Juzgado', value: 'juzgado' },
        { text: 'Tipo Proceso', value: 'tipo_proceso' },
        { text: 'Radicado', value: 'radicado' },
        { text: 'Demandante', value: 'demandante' },
        { text: 'Demandado', value: 'demandado' },
        { text: 'Usuario Registra', value: 'usuario_registra' },
        { text: 'Ubicacion', value: 'ubicacion' },
        { text: 'Columna', value: 'columna' },
        { text: 'Fila', value: 'fila' },
        { text: 'Caja', value: 'caja' },
      ],

      headers: [

       { text: 'Id', value: 'id' },
       { text: 'Ubicacion', value: 'ubicacion' },
        { text: 'Columna', value: 'columna' },
        { text: 'Fila', value: 'fila' },
        { text: 'Caja', value: 'caja' },
        { text: 'Juzgado', value: 'juzgado.nombre' },
        { text: 'Tipo Proceso', value: 'tipo_proceso.nombre' },
        { text: 'Radicado', value: 'radicado' },
      /*  { text: 'Codigo Anterior', value: 'codigo_anterior' },*/
        { text: 'Demandante Nombre', value: 'demandante.nombres' },
        { text: 'Demandante Apellidos', value: 'demandante.apellidos' },
        { text: 'Demandado Nombre', value: 'demandado.nombres' },
        { text: 'Demandado Apellidos', value: 'demandado.apellidos' },
        { text: 'Usuario Registra', value: 'usuario_registra.nombre' },
        { text: 'Estado préstamo', value: 'estado_prestamo' },
        { text: 'Acciones', value: 'action', sortable: false }
      ],
      itemIndex: -1,
      actionOnItem:'',
      editedItem: {},
      detallesItemId: null
    }),

    created(){


    //   this.fetchExpedientes();

    },

    /*mounted(){

      EventBus.$on('items_refreshed', (items) => {
        this.items = items;

      });


    },*/

    computed: {
        axiosParams() {
            const params = new URLSearchParams();
            params.append('tipo', this.tipo);
            params.append('buscar', this.buscar);
            return params;
        }
    },


    methods: {



      fetchExpedientes(){

         axios.get('api/expediente', {
                params: this.axiosParams
            })
              .then(response=>{
                  this.items=response.data.data
                  console.log(this.items)
               })
              .catch((error)=>{
                    console.log(error);

              })
       },

       verItem(item){
           this.detallesItemId = item;
       },

       deleteItem(item) {


        this.$alertify.confirmWithTitle("Borrar registro", "Está seguro de eliminar este registro?", ()=>{
            axios.delete('api/country/'+item.id)
                      .then(response=>{
                        this.items=response.data.data;
                        this.$alertify.error("Registro ha sido eliminado!");
                      })
                      .catch((error)=>{
                    console.log(error);

                   });

                      },() =>this.$alertify.error("Acción cancelada!"))


        },

         editItem (item) {



          this.actionOnItem='edit';
          this.itemIndex = this.items.indexOf(item);
          this.editedItem = Object.assign({}, item);
          this.openForm=true;

        },






    }



  }
</script>
