<template>

        <v-card class="elevation-12">
              <v-toolbar
                color="indigo"
                dark
                flat
              >
                <v-toolbar-title>Login</v-toolbar-title>
                             
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Email"
                    
                    v-model="email"
                    prepend-icon="person"
                    type="text"
                  />

                  <v-text-field
                    id="password"
                    label="password"                  
                    v-model="password"
                    prepend-icon="lock"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="indigo" dark @click="login">Login</v-btn>

              </v-card-actions>
            </v-card>



</template>  

<script>

import { EventBus } from '../EventBus';
import { mapActions } from 'vuex';

export default {
    
  data:() => ({

    email:'',
    password:'',
    show:false
        
    }),


  methods: {


     ...mapActions([
      
   
    ]),


    login(){

    
        axios.post('/api/login',{email:this.email,password:this.password})
                    .then(response => {

                      localStorage.setItem('access_token', response.data.access_token);
                      localStorage.setItem('logged_user', JSON.stringify(response.data.user));

                     //console.log(response.data.user);
                      this.$auth.setUser(response.data.user);
                      EventBus.$emit("user_logged_in");

                   //   this.$router.push('/');
            
                    }).catch((error)=>{
                        //console.log(error.response.data.message);
                        this.$alertify.error(error.response.data.message);
                    });


      },

     }
};


</script>       
