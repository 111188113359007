<template>
  <div>
    <v-form ref="form" v-model="valid_form" lazy-validation>
     <v-dialog v-model="openDialog" max-width="500px">

       <template v-slot:activator="{ on }">
          <v-btn
             v-if="icon"
             fab
             x-small
             slot="activator"
             color="primary" dark
             @click="setModalCreate" 
             v-on="on"
          >
            <v-icon>add</v-icon>
          </v-btn>

        <v-btn v-else slot="activator" color="primary" dark   @click="setModalCreate" v-on="on">Crear {{title}}</v-btn>
          
      </template>  
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12  sm10 md8>
                <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
              </v-flex>

            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn color="pink"  dark  @click.native="clear">Limpiar</v-btn>
           <v-btn color="blue"  dark  @click.native="close">Cancelar</v-btn>
           <v-btn color="green" dark  :disabled="!valid_form" @click.native="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>

  </div>
</template>


<script>
 
  import { EventBus } from "../../EventBus"
  export default {

    props:[
          
          'icon',  
          'itemIndex',
          'inputItem',
          'actionItem'
    ],  

  data: () => ({
      
      valid_form:true,
      openDialog:false, 
      editedItem: {},
      actionOnItem:'',
      defaultItem: {
            name: ''  
      }, 
      title:'Pais'   
     
  }),

  
  computed: {

      formTitle () {
        return this.actionOnItem == 'create' ? ('Crear ' + this.title) : ('Editar ' + this.title)
      },

  },

   watch: {
      
      inputItem: function(val) {

        if (Object.keys(val).length){

          this.editedItem=Object.assign({}, val);
          this.actionOnItem='edit';
          this.openDialog=true;
        }
      },

      
    },


  methods: {


        createItem(){

           if (this.$refs.form.validate()) {

              axios.post('api/country',this.editedItem)
                .then(response=>{
                    
                    
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido agregado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close()

        },

        updateItem(){

           if (this.$refs.form.validate()) {

              axios.put('api/country/'+this.editedItem.id,this.editedItem)
                .then(response=>{
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido actualizado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close();

        },

        save(){

          if (this.actionOnItem =='create') {
              this.createItem();
             }
          else {
             this.updateItem();
           }
        },

        setModalCreate(){

          this.actionOnItem='create';
          this.editedItem = Object.assign({}, this.defaultItem);
        },

        close () {

          this.openDialog = false;

         },

        clear () {
          this.$refs.form.reset();
        },

    }



    
  
   
  
  }
</script>
