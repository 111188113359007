export default  {

    methods: {


        cargar_usuarios(pagina){

         // console.log("entre acá mix"+pagina);

         this.show_loading_spinner=true
         axios.get('api/listar_usuarios?page='+pagina)

        .then(response =>{

         // console.log(response.data)
          this.usuarios = response.data
          //this.page=response.data.current_page
          //this.pageCount=response.data.last_page
          this.show_loading_spinner=false

          })
            .catch(error => {
              console.log(error)
             this.show_loading_spinner=true
           });

        },

        listar_usuarios(){

            // console.log("entre acá mix 1");

              axios.get('api/listar_usuarios')
             .then(response=>{

                this.usuarios = response.data.data

                // alert(JSON.stringify(response.data));
                //  console.log(response.data);

             })
             .catch((error)=>{
             console.log(error);
             });



            },

        listar_bodegas_activas(){

         // console.log("entre acá mix 1");

           axios.get('api/listar_bodegas_activas')
          .then(response=>{

              this.bodegas=response.data

             // alert(JSON.stringify(response.data));
              console.log(response.data);

          })
          .catch((error)=>{
          console.log(error);
          });



         },

         listar_estantes_activos(){

          // console.log("entre acá mix 1");

            axios.get('api/listar_estantes_activos')
           .then(response=>{

               this.estantes=response.data
              // this.estantes.unshift({id:1,siglas:"E1A"})

              // alert(JSON.stringify(response.data));
               //console.log(response.data);

           })
           .catch((error)=>{
           console.log(error);
           });



          },

          listar_cajas(){

            // console.log("entre acá mix 1");

              axios.get('api/listar_cajas')
             .then(response=>{

                 this.cajas=response.data

                // alert(JSON.stringify(response.data));
                 //console.log(response.data);

             })
             .catch((error)=>{
             console.log(error);
             });



            },

            listar_juzgados(){

              // console.log("entre acá mix 1");

                axios.get('api/listar_juzgados')
               .then(response=>{

                   this.juzgados=response.data
                //    console.table(this.juzgados)

                  // alert(JSON.stringify(response.data));
                  // console.log(response.data);

               })
               .catch((error)=>{
               console.log(error);
               });



              },

              listar_partes(){

                // console.log("entre acá mix 1");

                  axios.get('api/listar_partes')
                 .then(response=>{

                     this.partes=response.data

                    // alert(JSON.stringify(response.data));
                    // console.log(response.data);

                 })
                 .catch((error)=>{
                 console.log(error);
                 });



                },

            listar_expedientes_asociados_caratula (){


              axios.get('api/listar_expedientes_asociados_caratula/'+this.editedItem.id+'/listar_expedientes_asociados_caratula')
              .then(response=>{

                  this.expedientes_caratulas=response.data


              })
              .catch((error)=>{
              console.log(error);
              });



            },
            generarQR(){



              axios.get('api/QR/'+this.editedItem.id+'/QR')
                   .then(response=>{
                       this.qr=response.data

               });

           },

           listar_tipo_procesos(){

            // console.log("entre acá mix 1");

              axios.get('api/listar_tipo_procesos')
             .then(response=>{

                 this.tipo_procesos=response.data

                // alert(JSON.stringify(response.data));
               //  console.log(response.data);

             })
             .catch((error)=>{
             console.log(error);
             });



            },
            listar_usuario_session(){

             
              //alert("entre");
              var myItem = JSON.parse(localStorage.getItem('logged_user')).id;
              //alert(myItem);

              this.usuario_session=myItem;
              
              
  
              },



    }
}
