<template>
  
  <div>

  <parteList></parteList>
  

</div>
</template>

<script>



import ParteList from '../components/Parametrizacion/ParteList';


export default {

    components:{ParteList},

   

 }


</script>




