/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Vuetify from 'vuetify';
import VueAlertify from "vue-alertify";
import Vue2Filters from 'vue2-filters'
import router from './router';
import store from './store';
import Auth from './Auth';
import 'vuetify/dist/vuetify.min.css';
import VueI18n from 'vue-i18n';
import es from 'vuetify/src/locale/es.ts';
import en from 'vuetify/src/locale/en.ts';
import myMixins from './mixins.js';
import VueHtmlToPaper from 'vue-html-to-paper';


const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, options);

Vue.mixin(myMixins);

//console.log(window.user)

var Vue2FiltersConfig = {
  // capitalize: {
  //   onlyFirstLetter: false
  // },
  // number: {
  //   format: '0',
  //   thousandsSeparator: ' ',
  //   decimalSeparator: '.'
  // },

  percent: {
    decimalDigits: 2
  },
  currency: {
    symbol: '€',
    decimalDigits: 2,
    thousandsSeparator: '  ',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false
 
  },
  // pluralize: {
  //   includeNumber: false
  // },
  // ordinal: {
  //   includeNumber: false
  // }
}



let logged_user=((localStorage.getItem('logged_user') != null ) ? JSON.parse(localStorage.getItem('logged_user')) : null);

Vue.prototype.$auth= new Auth(logged_user);

Vue.use(VueI18n)
Vue.use(Vuetify);
Vue.use(VueAlertify);
Vue.use(Vue2Filters,Vue2FiltersConfig)


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('main-layout', require('./components/MainLayout.vue').default);
Vue.component('usuarios-crear', require('./components/UsuariosCrear.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

 const messages = {
    'es': {

      'label':{
        'name':'Nombre',
        'city':'Ciudad',
        'country':'Pais',
        'search':'Buscar',
        'newa':'Nueva',
        'newo':'Nuevo',
        'tender':'Propuesta',
        'effort': 'Dedicación'
      
      }
      
    }
}


const i18n = new VueI18n({
   locale: 'es', // set locale
   messages, // set locale messages
});


const app = new Vue({
    el: '#app',
    vuetify : new Vuetify({

      lang: {
             locales: {
                 es,
                 en,
             },
             current: 'es'
         }

      // lang: {
        //   t: (key, ...params) => i18n.t(key, params),
        // },

    }),
    i18n,
    router,
    store
});



