<template>
<div>
  <v-data-table
    :key="randomKey"
    :headers="headers"
    :items="items_p"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <!-- <component :is="formComponentName"  v-bind="formProps"  ></component> -->

        <parte-form   :itemIndex="itemIndex"  :inputItem="editedItem" :actionItem="actionOnItem" ></parte-form>
        <v-spacer></v-spacer>      
        </v-toolbar>
     <!--  <v-text-field v-model="search" label="buscar" class="mx-4"  prepend-icon="search"></v-text-field>-->
       

     <v-container>
            <v-layout>
                <v-flex xs3 md3>
                    <v-select
                        :items="tipos"
                        v-model="tipo"
                        label="Buscar por:"
                        prepend-icon="type"
                    ></v-select>
                </v-flex>
                 <v-flex xs3 md3>
                    <v-text-field v-model="buscar" label="Texto a buscar" class="mx-4"></v-text-field>
                </v-flex>
                <v-flex xs2 md2>
                    <v-btn
                        class="ma-2"
                        color="primary"
                        dark
                        @click="fetchItems()"
                        :disabled="tipo && !(buscar && buscar.length)"
                    >
                        Buscar
                        <v-icon
                        dark
                        right
                        >
                        search
                        </v-icon>
                    </v-btn>
                </v-flex> 
                 <v-flex xs3 md3>
                    <v-text-field v-show="items_p.length" v-model="search" label="filtrar en los resultados" class="mx-4"  prepend-icon="search"></v-text-field>
                </v-flex>
            </v-layout>
        </v-container>







      
    </template>
       
    <template v-slot:item.action="{ item }">
      
      

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
      <v-icon
        small
        @click="selectItem(item)"
        v-on="on"
      >
        check_box
      </v-icon>
    </template>
      <span>Seleccionar</span>
    </v-tooltip>


    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="fetchItems">Recargar</v-btn>
    </template>
  </v-data-table>

</div>

</template>

<script>

import ParteForm from "./ParteForm"
import { EventBus } from "../../EventBus"

  export default {


    components:{'parte-form':ParteForm},
    props:[''],


    data: () => ({
      
      items_p:[],
      search: '',
      seleccionado: '',
     
      openForm: false,
       tipo: '',
       buscar: '',

    tipos: [
        { text: 'Nombres', value: 'nombres' },
        { text: 'Apellidos', value: 'apellidos' },
        { text: 'Identificación', value: 'documento' },
        
      ],


      headers: [
       
        { text: 'Nombres', value: 'nombres' },
        { text: 'Apellidos', value: 'apellidos' },
        { text: 'Identificación', value: 'documento' },
        { text: 'Seleccionar', value: 'action', sortable: false }
      ],
      itemIndex: -1,
      actionOnItem:'',
      editedItem: {}
    }),

    created(){

      //this.fetchItems();

    },

    mounted(){

      EventBus.$on('items_refreshed', (items_p) => {
        this.items_p = items_p;
        
      });
    

    },

  computed: {
    row_id() {
      return this.seleccionado;
    },
    randomKey() {
      return this.items_p.length + '_' + Math.random();
    },
     axiosParams() {
            const params = new URLSearchParams();
            params.append('tipo', this.tipo);
            params.append('buscar', this.buscar);
            return params;
        },
  },


    methods: {



      fetchItems(){



         axios.get('api/parte',{
                params: this.axiosParams
            })
              .then(response=>{
                  this.items_p = [...response.data.data];
                  //this.items_p=response.data.data
               })
              .catch((error)=>{
                    console.log(error);

              })
       },

       deleteItem(item) {


        this.$alertify.confirmWithTitle("Borrar registro", "Está seguro de eliminar este registro?", ()=>{
            axios.delete('api/parte/'+item.id)
                      .then(response=>{
                        this.items=response.data.data;
                        this.$alertify.error("Registro ha sido eliminado!");
                      })
                      .catch((error)=>{
                    console.log(error);

                   });

                      },() =>this.$alertify.error("Acción cancelada!"))


        },
        selectItem(item) {
        

         //this.seleccionado = item.id;
         EventBus.$emit("parte_seleccionada",item);
         EventBus.$emit("cerrar_modal");
         

         // this.row_id= item.id;
         

        /*this.$alertify.confirmWithTitle("Borrar registro", "Está seguro de eliminar este registro?", ()=>{
            axios.delete('api/parte/'+item.id)
                      .then(response=>{
                        this.items=response.data.data;
                        this.$alertify.error("Registro ha sido eliminado!");
                      })
                      .catch((error)=>{
                    console.log(error);

                   });

                      },() =>this.$alertify.error("Acción cancelada!"))*/


        },

         editItem (item) {

          this.actionOnItem='edit';
          this.itemIndex = this.items.indexOf(item);
          this.editedItem = Object.assign({}, item); 
          this.openForm=true;
       
        },


   


    }

      
    
  }
</script>