<template>

  <div>

  <solicitudDesarchivoList></solicitudDesarchivoList>


</div>
</template>

<script>



import solicitudDesarchivoList from '../components/SolicitudDesarchivo/SolicitudDesarchivoList';


export default {

    components:{solicitudDesarchivoList},



 }


</script>




