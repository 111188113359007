import router from './router';

export default class Auth{


	constructor(user){

		this.user=user;
	}

	getUser(){
		return this.user;
	}

	setUser(user){

		this.user=user;

	}

	isLoggedIn(){

		//return (this.user!=null);

		return (localStorage.getItem('access_token') != null)
	}

	logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('logged_user');
        this.user=null;
        location.reload();
       // router.replace('/').catch(err => {});
        
    }

    check() {

        if (localStorage.getItem('access_token') !== null) {

            axios.get('api/user').then(response => {

               console.log('user is already logged in!!');
               
                //this.setUser(JSON.parse(JSON.stringify(response.data)));
                this.setUser(response.data);
            });
        }
    }

	

}