<template>
<div>
    <v-chip
        v-if="inputItem.estado_solicitud==1"
        class="ma-2"
        color="red"
        text-color="white"
        >
        Pendiente&nbsp;<small>{{inputItem.fecha_estado_solicitud}}</small>
    </v-chip>
    <v-chip
        v-if="inputItem.estado_solicitud==2"
        class="ma-2"
        color="orange"
        text-color="white"
        >
        En trámite&nbsp;<small>{{inputItem.fecha_estado_solicitud}}</small>
    </v-chip>
    <v-chip
        v-if="inputItem.estado_solicitud==3"
        class="ma-2"
        color="green"
        text-color="white"
        >
        Realizada&nbsp;<small>{{inputItem.fecha_estado_solicitud}}</small>
    </v-chip>
</div>

</template>

<script>

  export default {

    props:[
        'inputItem',
    ]
  }
</script>
