import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'


import Home from '../pages/Home'
import Solicitud from '../pages/Solicitud'
import Page2 from '../pages/Page2'
import ListarQR from '../pages/ListarQR'
import Page1 from '../pages/Page1'
import Paises from '../pages/Paises'
import Archivo from '../pages/Archivo'
import Expediente from '../pages/Expediente'
import Estantes from '../pages/Estantes'
import Ref2 from '../pages/Ref2'
import Parte from '../pages/Parte'
import Caratula from '../pages/Caratula'
import Imprimir from '../directives.js'
import SolicitudDesarchivo from '../pages/SolicitudDesarchivo'
import GestionDesarchivo from '../pages/GestionDesarchivo'



const ifNotAuthenticated = (to, from, next) => {
  if (!Vue.prototype.$auth.isLoggedIn()) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (Vue.prototype.$auth.isLoggedIn()) {
    next()
    return
  }
  if (router.path !== '/') {
    //next('/')
    router.push('/')
  }

}


Vue.use(Router)

const router = new Router({
	mode: 'history',
	  	routes: [

          {
            path: '/',
            name: 'Home',
            component: Home
          },
			   {
			      path: '/solicitud',
			      name: 'Solicitud',
			      component: Solicitud,
            beforeEnter: ifAuthenticated
          },
          {
            path: '/expediente',
            name: 'Expediente',
            component: Expediente,
            beforeEnter: ifAuthenticated
          },

			    {
			      path: '/page2',
			      name: 'Page2',
			      component: Page2,
            beforeEnter: ifAuthenticated
			    },
			    {
			      path: 'ListarQR/:id',
			      name: 'ListarQR',
			      component: ListarQR,
            //beforeEnter: ifAuthenticated
			    },
          {
            path: '/paises',
            name: 'Paises',
            component: Paises,
            beforeEnter: ifAuthenticated
          },
          {
            path: '/estantes',
            name: 'Estantes',
            component: Estantes,
            beforeEnter: ifAuthenticated
          },
          {
            path: '/ref2',
            name: 'Ref2',
            component: Ref2,
            beforeEnter: ifAuthenticated
          },
          {
            path: '/Archivo/:id',
            name: 'Archivo',
            component: Archivo,

          },
          {
            path: '/Parte',
            name: 'Parte',
            component: Parte,
            beforeEnter: ifAuthenticated
          },
          {
            path: '/Caratula',
            name: 'Caratula',
            component: Caratula,
            beforeEnter: ifAuthenticated
          },
          {
            path: '/solicitud-desarchivo',
            name: 'Solicitud-Desarchivo',
            component: SolicitudDesarchivo,
            beforeEnter: ifAuthenticated
          },
          {
            path: '/gestion-desarchivo',
            name: 'Gestion-Desarchivo',
            component: GestionDesarchivo,
            beforeEnter: ifAuthenticated
          }

 		],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!Vue.prototype.$auth.isLoggedIn()) {

    //if ((typeof(localStorage.getItem('access_token'))=='undefined') ||  (localStorage.getItem('access_token') == null)){
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
});


export default router;
