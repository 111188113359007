<template>
  
  <div>

  <ArchivoSolicitud></ArchivoSolicitud>
  

</div>
</template>

<script>



import ArchivoSolicitud from '../components/Archivo/ArchivoSolicitud';


export default {

    components:{ArchivoSolicitud},

   

 }


</script>




