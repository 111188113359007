<template>
  <div>
    <v-form ref="form" v-model="valid_form" lazy-validation> 
     <v-dialog v-model="openDialog" max-width="900px" scrollable>

       <template v-slot:activator="{ on }">
          <v-btn
             v-if="icon"
             fab
             x-small
             slot="activator"
             color="primary" dark
             @click="setModalCreate" 
             v-on="on"
          >
            <v-icon>add</v-icon>
          </v-btn>

        <v-btn v-else slot="activator" color="primary" dark   @click="setModalCreate" v-on="on">Crear {{title}}</v-btn>
          
      </template>  
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
            <!-- Campos del Modal de Crear Expediente -->
              <v-flex xs12 md6>
                <v-select
                  :items="estantes"
                  v-model="formParams.estante_id"
                  label="Estante"
                  prepend-icon="store"
                  item-text="siglas"
                  item-value="id"
                  :rules="[rules.required]"
                  return-object
                  
                ></v-select>
              </v-flex>
               <v-flex xs12 md6>
                <v-select
                v-model="formParams.columna" 
                :items="columna"
                label="Columna"
                prepend-icon="view_column"
                :rules="[rules.required]"
              ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-select
                v-model="formParams.fila" 
                :items="fila"
                label="Fila"
                prepend-icon="table_rows"
                :rules="[rules.required]"
              ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-select
                  :items="cajas"
                  v-model="formParams.caja"
                  label="Caja"
                  prepend-icon="all_inbox"
                  item-text="nombre"
                  item-value="id"
                  :rules="[rules.required]"
                  return-value
                ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-col cols="12">
                <v-autocomplete
                  v-model="formParams.juzgado_id"
                  :items="juzgados"
                  item-text="nombre"
                  v-on:change="cargarCodigo"
                  dense
                  label="Juzgado"
                  prepend-icon="apartment"
                  :rules="[rules.required]"
                 return-object
                ></v-autocomplete>
              </v-col>
              </v-flex>
              <v-flex xs12 md6>
                <v-col cols="12">
                <v-autocomplete
                  v-model="formParams.tipo_proceso_id"
                  :items="tipo_procesos"
                  item-text="nombre"
                  item-value="id"
                  dense
                  label="Tipo Proceso"
                  prepend-icon="apartment"
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>
              </v-flex>
                <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.codigo_anterior" 
                    label="Código Despacho" 
                    prepend-icon="short_text"  
                    maxlength="100"
                    readonly
                    >       
                </v-text-field>
              </v-flex> 
              <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.radicado" 
                    label="Radicado" 
                    prepend-icon="short_text"  
                    maxlength="100"
                    type="number"
                    :rules="[rules.required]"
                    >       
                </v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.folios" 
                    label="Folios" 
                    prepend-icon="short_text"  
                    :rules="[rules.required]"
                    >       
                </v-text-field>
              </v-flex>
               <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.cuadernos" 
                    label="Cuadernos" 
                    prepend-icon="short_text"  
                    maxlength="100"
                    type="number"
                    :rules="[rules.required]"
                    >       
                </v-text-field>
              </v-flex>
            
                <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.anio" 
                    label="Año Archivo" 
                    prepend-icon="calendar_today"  
                    maxlength="4"
                    :rules="[rules.required]"
                >       
                </v-text-field>
              </v-flex> 
              <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.posicion" 
                    label="Posicion" 
                    prepend-icon="format_indent_increase"  
                    maxlength="3"
                    :rules="[rules.required]"
                >       
                </v-text-field>
              </v-flex>

               <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.demandante_nombre" 
                    label="demandante" 
                    prepend-icon="short_text"  
                    maxlength="100"
                    @click="buscarParte('demandante')"
                    :rules="[rules.required]"
                    >       
                </v-text-field>
              </v-flex>

                <v-flex xs12 md6>
                <v-text-field 
                    v-model="formParams.demandado_nombre" 
                    label="demandado" 
                    prepend-icon="short_text"  
                    maxlength="100"
                    @click="buscarParte('demandado')"
                    :rules="[rules.required]"
                    
                    >       
                </v-text-field>
              </v-flex>


        <!-- <v-btn color="green"  @click.native="buscarParte">parte</v-btn> -->
          
         <v-dialog v-model="openDialog1" max-width="900px" scrollable>
           <parteSelect :row_id="0"></parteSelect>
          </v-dialog>
            

            
                 

            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn color="pink"  dark  @click.native="clear">Limpiar</v-btn>
           <v-btn color="blue"  dark  @click.native="close">Cancelar</v-btn>
           <v-btn color="green" dark  :disabled="!valid_form" @click.native="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>

  </div>
</template>


<script>
 
  import { EventBus } from "../../EventBus"
  import ParteSelect from "../Parametrizacion/ParteSelect"
  export default {

    components:{ParteSelect},

    props:[
          
          'icon',  
          'itemIndex',
          'inputItem',
          'actionItem',
          'row_id',
    ],  

  data: () => ({

      
      
      
      rules: {
          required: value => !!value || 'Campo requerido',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
      estantes:[], 
      cajas:[],
      juzgados:[],  
      partes:[],
      tipo_procesos:[],
      valid_form:true,
      openDialog:false, 
      openDialog1:false, 
      editedItem: {},
      actionOnItem:'',
      tipo_parte:'',
      candadoCrear: true,
      //debo inicializar todas las variables aquí.
    


      formParams: { 
        demandante_id:'',
        demandado_id:'',
        demandante_nombre:'',
        demandado_nombre:'',
        estante_id:{id:1,descripcion:"Estante 1 A",siglas:"E1A"},
           
      },
      
      defaultItem: {
            name: ''  
      }, 
      title:'Expediente',
     columna: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
     fila: [1, 2, 3, 4, 5, 6, 7, 8],
     //'formParams.estante_id':{id:1,descripcion:"Estante 1 A",siglas:"E1A"},
     

    
       
     
  }),

  
  computed: {

      formTitle () {
      
        /* if(this.actionOnItem == 'create')
        {
          this.clear();
        } */

        return this.actionOnItem == 'create' ? ('Crear ' + this.title) : ('Editar ' + this.title)
      },

      ubicacion () {
        this.formParams.ubicacion=3;
      },





  },

   created () {

     //this.listar_bodegas_activas();
     this.listar_estantes_activos();
     this.listar_cajas();
     this.listar_juzgados();
     this.listar_partes();
     this.listar_tipo_procesos();
     this.listar_usuario_session();
    
     EventBus.$on('parte_seleccionada', (item) => {  

    if(this.tipo_parte=='demandante'){
     this.formParams.demandante_nombre = item.nombres+" "+item.apellidos;
     this.formParams.demandante_id= item.id;
     }
     else if(this.tipo_parte=='demandado'){
     this.formParams.demandado_nombre = item.nombres+" "+item.apellidos;
     this.formParams.demandado_id= item.id;
     }
      //this.formParams.usuario_session=this.usuario_session;

     });

     EventBus.$on('cerrar_modal', () => {  
     this.openDialog1 = false;
     });

   }, 

   watch: {
      
      inputItem: function(val) {

     
     
      
        if (Object.keys(val).length){

          this.editedItem=Object.assign({}, val);
          this.formParams = this.editedItem;
          this.formParams.demandante_nombre=this.formParams.demandante.nombres+" "+this.formParams.demandante.apellidos;
          this.formParams.demandado_nombre=this.formParams.demandado.nombres+" "+this.formParams.demandado.apellidos;
          this.formParams.juzgado_id=this.formParams.juzgado;
          this.formParams.tipo_proceso_id=this.formParams.tipo_proceso.id;
          this.formParams.demandante_id= this.formParams.demandante.id;
          this.formParams.demandado_id= this.formParams.demandado.id;
          this.formParams.radicado= this.formParams.radicado.substr(12);
          this.formParams.codigo_anterior= this.formParams.juzgado.codigo;
        
      

         
      

         
          this.actionOnItem='edit';
          this.openDialog=true;
        }
      },

       'formParams.capacidad': function (val){
         this.formParams.utilizado = 0;
         this.formParams.disponible = val;
       },

     

      
    },


  methods: {


        createItem(){

            if (this.$refs.form.validate() && this.candadoCrear) {

              this.candadoCrear = false

              this.formParams.usuario_session=this.usuario_session;
             
              axios.post('api/expediente',this.formParams)
                .then(response=>{
                             
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido agregado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
            }
           
            this.close() 
            location.reload();

        },

        updateItem(){
          
          if (this.$refs.form.validate()) {

            
               axios.post('api/expediente',this.formParams)
                .then(response=>{
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido actualizado!")
                })
                .catch((error)=>{
                console.log(error);
            });
          }else{
             return false;
          }

          this.close();
          location.reload();
            
        },

        save(){

          if (this.actionOnItem =='create') {
              this.createItem();
             }
          else {
             this.updateItem();
           }
        },

        setModalCreate(){
          
          this.clear();
          this.actionOnItem='create';
          this.editedItem = Object.assign({}, this.defaultItem);
        },

        close () {

          this.openDialog = false;

         },

        clear () {
          this.$refs.form.reset();
        },

        buscarParte(tipo_parte){

          this.tipo_parte=tipo_parte;
          this.openDialog1=true;

          
        
        },

        
        cargarCodigo(){

        
               this.formParams.codigo_anterior = this.formParams.juzgado_id.codigo;

          
        
        },
     




    }



    
  
   
  
  }
</script>
