<template>
  
  <div>

  <countryList></countryList>
  

</div>
</template>

<script>



import CountryList from '../components/Country/CountryList';


export default {

    components:{CountryList},

   

 }


</script>




