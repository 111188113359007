<template>
<div>
    <v-chip
        v-if="inputItem.estado_desarchivo==1"
        class="ma-2"
        color="blue"
        text-color="white"
        >
        En Búsqueda&nbsp;<small>{{inputItem.fecha_estado_desarchivo}}</small>
    </v-chip>
    <v-chip
        v-if="inputItem.estado_desarchivo==2"
        class="ma-2"
        color="red"
        text-color="white"
        >
        Paquete Errado&nbsp;<small>{{inputItem.fecha_estado_desarchivo}}</small>
    </v-chip>
    <v-chip
        v-if="inputItem.estado_desarchivo==3"
        class="ma-2"
        color="green"
        text-color="white"
        >
        En Oficina Judicial&nbsp;<small>{{inputItem.fecha_estado_desarchivo}}</small>
    </v-chip>
    <v-chip
        v-if="inputItem.estado_desarchivo==4"
        class="ma-2"
        color="green"
        text-color="white"
        >
        En Juzgado&nbsp;<small>{{inputItem.fecha_estado_desarchivo}}</small>
    </v-chip>
    <v-chip
        v-if="inputItem.estado_desarchivo==5"
        class="ma-2"
        color="orange"
        text-color="white"
        >
        Devuelto a Archivo&nbsp;<small>{{inputItem.fecha_estado_desarchivo}}</small>
    </v-chip>
</div>

</template>

<script>

  export default {

    props:[
        'inputItem',
    ]
  }
</script>
