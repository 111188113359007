<template>
  <div>
    <v-form ref="form" v-model="valid_form" lazy-validation>
     <v-dialog v-model="openDialog" max-width="900px" scrollable>

       <template v-slot:activator="{ on }">
          <v-btn
             v-if="icon"
             fab
             x-small
             slot="activator"
             color="primary" dark
             @click="setModalCreate"
             v-on="on"
          >
            <v-icon>add</v-icon>
          </v-btn>

        <v-btn v-else slot="activator" color="primary" dark   @click="setModalCreate" v-on="on">Crear {{title}}</v-btn>

      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>

          <v-container grid-list-md>
            <v-layout wrap>
            <!-- Campos del Modal de Crear Solicitud de Desarchivo -->

            <v-flex xs12 md6>
 
                <v-col cols="12">
                 
                <v-autocomplete
                  v-model="formParams.juzgado_solicitante_id"
                  :items="juzgados"
                  item-text="nombre"
                  dense
                  label="Entidad Solicitante"
                  prepend-icon="apartment"
                  :rules="[rules.required]"
                  item-value="id"
                ></v-autocomplete>
                </v-col>
            </v-flex>

          
            <v-flex xs12 md6>
                <v-text-field
                    v-model="formParams.solicitante"
                    label="Nombre del Solicitante"
                    prepend-icon="mdi-account-question"
                    type="text"
                    :rules="[rules.required]"

                    >
                </v-text-field>
            </v-flex>

            <v-flex xs12 md12>
                <v-card>
                    <v-card-title>
                    <span class="headline">Radicado: {{ numRadicado }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-layout wrap>
                            <v-flex xs12 md6>
                                <v-col cols="12">
                                <v-autocomplete
                                v-model="formParams.juzgado_radicado_id"
                                :items="juzgados"
                                item-text="nombre"
                                dense
                                label="Juzgado"
                                prepend-icon="apartment"
                                :rules="[rules.required]"
                                item-value="id"
                                ></v-autocomplete>
                                </v-col>
                            </v-flex>

                            <v-flex xs12 md6>
                                <v-text-field
                                    v-model="formParams.codigo"
                                    label="Digite el código"
                                    prepend-icon="short_text"
                                    type="number"
                                    :rules="[rules.required, rules.counter]"

                                    >
                                </v-text-field>
                            </v-flex>
                        </v-layout>

                    </v-card-text>

                </v-card>

            </v-flex>

            <v-flex xs12 md6>

                <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="formParams.fecha_archivo"
                    label="Fecha en que se archivó"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="formParams.fecha_archivo"
                    :active-picker.sync="activePicker"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="saveDate"
                ></v-date-picker>
                </v-menu>

                <v-text-field
                    label="Digite el número del paquete o referencia del lote"
                    v-model="formParams.ubicacion_expediente"
                    ></v-text-field>

                    <v-text-field
                    label="URL del archivo o soporte relacionado con la solicitud"
                    v-model="formParams.url_adjunto_solicitud"
                    prepend-icon="mdi-attachment"
                    ></v-text-field>

            </v-flex>

            <v-flex xs12 md6>
                <v-textarea
                    filled
                    label="Observación sobre la solicitud"
                    name="observacion_solicitud"
                    v-model="formParams.observacion_solicitud"
                ></v-textarea>
            </v-flex>

             <v-flex xs12 md12>

                <v-col cols="12">
                <v-autocomplete
                  v-model="formParams.gestor_id"
                  :items="usuarios"
                  item-text="name"
                  dense
                  label="Enviar al gestor"
                  prepend-icon="mdi-account-arrow-right"
                  :rules="[rules.required]"
                  item-value="id"
                ></v-autocomplete>
                </v-col>
            </v-flex>

            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn color="pink"  dark  @click.native="clear">Limpiar</v-btn>
           <v-btn color="blue"  dark  @click.native="close">Cancelar</v-btn>
           <v-btn color="green" dark  :disabled="!valid_form" @click.native="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>

  </div>
</template>


<script>

  import { EventBus } from "../../EventBus"

  export default {

    props:[

          'icon',
          'itemIndex',
          'inputItem',
          'actionItem',
          'row_id',
    ],

  data: () => ({

value:{},
      rules: {
          required: value => !!value || 'Campo requerido',
          counter: value =>(value && value.length <= 11)|| 'Max 11 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },

      juzgados: [],
      usuarios: [],
      //juzgado_solicitante_id:1,

      activePicker: null,
      date: null,
      menu: false,

      valid_form:true,
      openDialog:false,

      actionOnItem:'',
      formParams: {},
      defaultItem: {},
      title:'Solicitud de Desarchivo'
  }),


  computed: {

      formTitle () {

        /* if(this.actionOnItem == 'create')
        {
          this.clear();
        } */

        return this.actionOnItem == 'create' ? ('Crear ' + this.title) : ('Editar ' + this.title)
      },

      numRadicado () {
          var codigo_juzgado = '';
        //  console.table(this.juzgados);
          // console.log("antes"+this.formParams.juzgado_radicado_id);
         

          if((this.formParams.codigo)&&(this.formParams.juzgado_radicado_id != 'undefined'&& this.formParams.juzgado_radicado_id != null ))
          {

            // console.log("despues"+this.formParams.juzgado_radicado_id);
              codigo_juzgado = this.juzgados.find(element => element.id == this.formParams.juzgado_radicado_id).codigo
              this.formParams.radicado = codigo_juzgado + this.formParams.codigo
          }
          return this.formParams.radicado;
      }
  },

   created () {

     this.listar_juzgados()
     this.listar_usuarios()
     this.listar_usuario_session()

   },

   watch: {

       menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },

      inputItem: function(val) {

        if (Object.keys(val).length){

          this.formParams=Object.assign({}, val);
          this.formParams.juzgado_solicitante_id = this.formParams.juzgado_solicitante_id *1;
          this.formParams.juzgado_radicado_id = this.formParams.juzgado_radicado_id*1;
          this.formParams.gestor_id = this.formParams.gestor_id*1;

        // this.formParams.juzgado_solicitante_id = parseInt(this.formParams.juzgado_solicitante_id);
          this.actionOnItem='edit';
          this.openDialog=true;
        }
      }
      
    },

    methods: {

      saveDate (date) {
        this.$refs.menu.save(date)
      },

        createItem(){

          if (this.$refs.form.validate()) {

            this.formParams.usuario_session=this.usuario_session;

              axios.post('api/solicitud_desarchivo',this.formParams)
                .then(response=>{

                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido agregado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close();
            location.reload();

        },

        updateItem(){

           if (this.$refs.form.validate()) {
               axios.put('api/solicitud_desarchivo/'+this.formParams.id,this.formParams)
                .then(response=>{
                    // console.log(response)
                    // EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido actualizado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close();
            location.reload();
        },

        save(){

          if (this.actionOnItem =='create') {
              this.createItem();
             }
          else {
             this.updateItem();
           }
        },

        setModalCreate(){

          this.clear();
          this.actionOnItem='create';
          this.formParams = Object.assign({}, this.defaultItem);
        },

        close () {

          this.openDialog = false;

         },

        clear () {
          this.$refs.form.reset();
        },
    }
  }
</script>
