<template>
  <div>
    <v-form ref="form" v-model="valid_form" lazy-validation>
     <v-dialog v-model="openDialog" max-width="1200px">

       <template v-slot:activator="{ on }">
          <v-btn
             v-if="icon"
             fab
             x-small
             slot="activator"
             color="primary" dark
             @click="setModalCreate" 
             v-on="on"
          >
            <v-icon>add</v-icon>
          </v-btn>

        <v-btn v-else slot="activator" color="primary" dark   @click="setModalCreate" v-on="on">Crear {{title}}</v-btn>
          
      </template>  
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12  md6>
                <v-text-field v-model="editedItem.name" label="Radicado" prepend-icon="mdi-file">
                </v-text-field>
                                          
              </v-flex>

              <v-flex xs12  md6>
               <v-tooltip bottom>
                <template v-slot:activator="{ on }">
               <v-icon
                small
                @click="buscarRadicadoSaidoj()"
                v-on="on"
               >
                  mdi-file-find
               </v-icon>
               </template>
              <span>Buscar Radicado</span>
              </v-tooltip>
             
              </v-flex>

               <v-flex xs12  md6>
                 <v-text-field
                  v-model="radicados.codigo_unico"
                  label="Proceso Saidoj"
                  ></v-text-field>
               </v-flex> 
                <v-flex xs12  md6>
             <v-img :aspect-ratio="16/9" src="/qrcodes/17001400400119943342800.svg">
        <v-row align="end" class="lightbox white--text pa-2 fill-height">         
        </v-row>
      </v-img>
      </v-flex>

               <v-flex xs12  md6>
                <v-text-field
                  v-model="radicados.id_clase_proc_nombre"
                  label="Clase de Proceso"
                  ></v-text-field>
               </v-flex>
              

                <v-flex xs12  md6>
                <v-text-field
                  v-model="radicados.id_tipo_proc_nombre"
                  label="Tipo de Proceso"
                  ></v-text-field>
               </v-flex>

               <v-flex xs12  md6>
                <v-text-field
                  v-model="radicados.funcionario"
                  label="Despacho"
                  ></v-text-field>
               </v-flex> 
                
              <v-flex xs12>
               
 <!-- <v-img :aspect-ratio="16/9" src="/qrcodes/17001400400119943342800.svg">
        <v-row align="end" class="lightbox white--text pa-2 fill-height">         
        </v-row>
      </v-img> -->

               
                 <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Nombre</th>
          <th class="text-left">Identificación</th>
          <th class="text-left">Tipo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in desserts" :key="item.name">
          <td>{{ item.name }}</td>
          <td>{{ item.id }}</td>
          <td>{{ item.tipo }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
               </v-flex> 
            </v-layout>
          



            
          </v-container>
          
        </v-card-text>
        
        
        <div class="title m-b-md">
       
      </div>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn color="pink"  dark  @click.native="clear">Limpiar</v-btn>
           <v-btn color="blue"  dark  @click.native="close">Cancelar</v-btn>
           <v-btn color="green" dark  :disabled="!valid_form" @click.native="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>

  </div>
</template>


<script>
 
  import { EventBus } from "../../EventBus"
  export default {

    props:[
          
          'icon',  
          'itemIndex',
          'inputItem',
          'actionItem'
    ],  

  data: () => ({

desserts: [
          { name: 'Pedro Perez Jaramillo',
            id: 159342342,
            tipo: 'Demandante'
          },
          {
            name: 'Marco Jaramillo Mendez',
            id: 12200000,
            tipo: 'Demandado'
          },
          {
            name: 'Maria Velez Jaramill0',
            id: 12300000,
            tipo: 'Demandado'
          }
        ],
      
      
      valid_form:true,
      openDialog:false, 
      editedItem: {},
      qr: {},
      actionOnItem:'',
      radicados:[],
      formParams:[],
      defaultItem: {
            name: ''  
      }, 
      title:'Archivo',
         
     
  }),

  
  computed: {

      formTitle () {
        return this.actionOnItem == 'create' ? ('Crear ' + this.title) : ('Editar ' + this.title)
      },

  },

   watch: {
      
      inputItem: function(val) {

        if (Object.keys(val).length){

          this.editedItem=Object.assign({}, val);
          this.actionOnItem='edit';
          this.openDialog=true;
        }
      },

      'editedItem.name': function (val){
         this.buscarRadicadoSaidoj();
      }

      
    },


  methods: {


        createItem(){

           if (this.$refs.form.validate()) {

              axios.post('api/country',this.editedItem)
                .then(response=>{
                    
                    
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido agregado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close()

        },

        updateItem(){

           if (this.$refs.form.validate()) {

              axios.put('api/country/'+this.editedItem.id,this.editedItem)
                .then(response=>{
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido actualizado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close();

        },

        save(){

          if (this.actionOnItem =='create') {
              this.createItem();
             }
          else {
             this.updateItem();
           }
        },

        setModalCreate(){

          this.actionOnItem='create';
          this.editedItem = Object.assign({}, this.defaultItem);
        },

        close () {

          this.openDialog = false;

         },

        clear () {
          this.$refs.form.reset();
        },

        buscarRadicadoSaidoj(){

           axios.get('api/buscar_radicadoSaidoj/'+this.editedItem.name+'/buscar_radicadoSaidoj')
                .then(response=>{    
                    this.radicados=response.data

            });

        },

        generarQR(){

           axios.get('api/QR/'+this.editedItem.name+'/QR')
                .then(response=>{    
                    this.qr=response.data

            });

        },


    }



    
  
   
  
  }
</script>
