<template>
<div>
    <v-dialog v-model="openDialogDetalles" max-width="900px" scrollable>
        <v-card>
        <v-card-title>
          <span class="headline">Solicitud de Desarchivo Nro. {{inputItem}}</span>
        </v-card-title>
        <v-card-text>

        <v-container class="grey lighten-5">
            <v-row no-gutters>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Solicitante
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{getJuzgadoSolicitante}}
                </v-card>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Persona que solicita
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{item.solicitante}}
                </v-card>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Radicado
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{item.radicado}}
                </v-card>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Fecha en que se archivó
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{item.fecha_archivo}}
                </v-card>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Número del paquete o referencia del lote
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{item.ubicacion_expediente}}
                </v-card>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    URL del archivo o soporte relacionado con la solicitud
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    <a :href="item.url_adjunto_solicitud" target="_blank">{{item.url_adjunto_solicitud}}</a>
                </v-card>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Observación sobre la solicitud
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{item.observacion_solicitud}}
                </v-card>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Gestor
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{getGestor}}
                </v-card>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Estado de la solicitud
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    <estado-solicitud :inputItem="item" ></estado-solicitud>
                </v-card>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Estado del desarchivo
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    <estado-desarchivo :inputItem="item" ></estado-desarchivo>
                </v-card>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Fecha Estado Desarchivo
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{item.fecha_estado_desarchivo}}
                </v-card>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    URL del archivo o soporte relacionado con la gestión
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    <a :href="item.url_adjunto_gestion" target="_blank">{{item.url_adjunto_gestion}}</a>
                </v-card>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    URL del expediente en digital
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    <a :href="item.url_expediente_digital" target="_blank">{{item.url_expediente_digital}}</a>
                </v-card>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Nombre de quién recibe el expediente
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{item.receptor}}
                </v-card>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    URL del acta de recibido
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{item.acta_recibido}}
                </v-card>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    Comentario de la gestión
                </v-card>
                </v-col>

                <v-col>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    {{item.observacion_gestion}}
                </v-card>
                </v-col>
            </v-row>
        </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn color="blue"  dark  @click.native="close">Cerrar</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>

</div>

</template>

<script>

import EstadoSolicitud from "./EstadoSolicitud"
import EstadoDesarchivo from "./EstadoDesarchivo"

  export default {

    components:{
        'estado-solicitud':EstadoSolicitud,
        'estado-desarchivo':EstadoDesarchivo
    },
    props:[
        'inputItem',
    ],

  data: () => ({

      openDialogDetalles:false,
      item: {},
      juzgados: [],
      usuarios: [],

  }),

  computed: {
      getJuzgadoSolicitante () {
          let juzgadoSolicitante = this.item.juzgado_solicitante_id?this.juzgados.find(element => element.id === this.item.juzgado_solicitante_id):''
          return juzgadoSolicitante?juzgadoSolicitante.nombre:''
      },
      getGestor () {
          let gestor = this.item.gestor_id?this.usuarios.find(element => element.id === this.item.gestor_id):''
          return gestor?gestor.name:''
      }
  },

   created () {

       this.listar_juzgados()
       this.listar_usuarios()

   },

   watch: {

      inputItem: function(val) {
          this.openDialogDetalles=true;
          this.fetchSolicitudDesarchivo()
      }
    },

    methods: {
        fetchSolicitudDesarchivo(){

         axios.get('api/solicitud_desarchivo/'+this.inputItem, {
                params: this.axiosParams
            })
              .then(response=>{
                  this.item=response.data.data
               })
              .catch((error)=>{
                    console.log(error);

              })
       },
        close () {

          this.openDialogDetalles = false;

        }
    }
  }
</script>
