<template>

  <div >
  
    <v-form ref="form" v-model="valid_form" lazy-validation> 
     
     <v-dialog v-model="openDialog" max-width="600px" scrollable >

       <template v-slot:activator="{ on }">
          <v-btn
             v-if="icon"
             fab
             x-small
             slot="activator"
             color="primary" dark
             @click="setModalCreate" 
             v-on="on"
          >
            <v-icon>add</v-icon>
          </v-btn>

        <!-- <v-btn  v-else slot="activator" color="primary" dark   @click="setModalCreate" v-on="on"></v-btn> -->
     
      </template>  
       
      <v-card  >
         

        <v-card-title >
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
         
          
            <!-- Campos del Modal de Crear Expediente -->
              <div id="container_print" style=" border-top-style: double;border-right-style: double;border-bottom-style: double;  border-left-style: double;" >
                <br>
                <div align="center"> <h2>Estante: {{ editedItem.siglas }} Columna: {{ editedItem.columna }} Fila: {{ editedItem.fila }}
                  
                </h2></div>
                <div align="center" style="font-size=300px" >Caja: {{ editedItem.caja }}</div>
             
               
                
                <div align="center"> 
                  <img :src="qr" width="320px">
                    <!--v-row align="end" class="lightbox white--text pa-2 fill-height">         
                    </v-row-->
                </div>
                 <div align="center"><h2>Ubicación: {{ editedItem.ubicacion_expediente }}</h2></div>
              </div>
                  
                       
                 

          
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <!--v-btn color="pink"  dark  @click.native="print()">Imprimir</v-btn-->
           <v-btn color="blue"  dark  @click.native="close">Cancelar</v-btn> &nbsp;
           <a v-print=" 'container_print' " color="pink"  dark ><v-btn color="pink"  dark>Imprimir</v-btn></a>
           
          <!--  <v-btn color="green" dark  :disabled="!valid_form" @click.native="save">Guardar</v-btn> -->
        </v-card-actions>
    
      </v-card>
    
    </v-dialog>

  </v-form>
    </div>
     
  

</template>


<script>

 
  import { EventBus } from "../../EventBus"
  import ParteSelect from "../Parametrizacion/ParteSelect"
  
  
  export default {

    components:{ParteSelect},

    props:[
          
          'icon',  
          'itemIndex',
          'inputItem',
          'actionItem',
          'row_id',
    ],  

  data: () => ({
      
      
      estantes:[], 
      expedientes_caratulas:[],
      qr: {},
      cajas:[],
      elem :"",
     
      valid_form:true,
      openDialog:false, 
      openDialog1:false, 
      editedItem: {},
      actionOnItem:'',
      tipo_parte:'',
      //debo inicializar todas las variables aquí.
      formParams: { 
       
           
      },
      defaultItem: {
            name: ''  
      }, 
      title:'Carátula',
     columna: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
     fila: ['1', '2', '3', '4', '5', '6'],
     

       
     
  }),

  
  computed: {

      formTitle () {
        return this.actionOnItem == 'create' ? ('Crear ' + this.title) : (' ' + this.title)
      },

     



  },

   created () {

  

   }, 

   watch: {
      
      inputItem: function(val) {

        if (Object.keys(val).length){

          this.editedItem=Object.assign({}, val);
          this.actionOnItem='edit';
          this.openDialog=true;
         

          //this.listar_expedientes_asociados_caratula();
          this.generarQR();
        }
      },



      
    },


  methods: {


        createItem(){

         

          if (this.$refs.form.validate()) {

              axios.post('api/expediente',this.formParams)
                .then(response=>{
                    
                    
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido agregado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close() 

        },

        updateItem(){

           if (this.$refs.form.validate()) {

              axios.put('api/estante/'+this.editedItem.id,this.editedItem)
                .then(response=>{
                    EventBus.$emit("items_refreshed", response.data.data);
                    this.$alertify.success("Registro ha sido actualizado!")
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             return false;
           }

            this.close();

        },

        save(){

          if (this.actionOnItem =='create') {
              this.createItem();
             }
          else {
             this.updateItem();
           }
        },

        setModalCreate(){

          this.actionOnItem='create';
          this.editedItem = Object.assign({}, this.defaultItem);
        },

        close () {

          this.openDialog = false;

         },

        clear () {
          this.$refs.form.reset();
        },

        
        print() {
               
          alert("sss");

          let style = document.createElement('style');
          style.type = 'text/css';
          style.appendChild(document.createTextNode("@media print{@page {size: landscape;align:center}}@media screen{#printSection{display:none}}@media print{#printSection,body{overflow:visible!important}body *{visibility:hidden}#printSection,#printSection *{visibility:visible}#printSection{font-size:60px;zoom:120%;position:absolute;left:0;top:0;right:0}#printSection table{width:100%!important}}"));
          document.body.appendChild(style);




                           
        },

    }

  
  }
</script>
