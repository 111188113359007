<template>

        <v-card class="elevation-12">
              <v-toolbar
                color="indigo"
                dark
                flat
              >
                <v-toolbar-title>Create your account</v-toolbar-title>                
                
              </v-toolbar>
              <v-card-text>
                <v-form ref="registerform">

                  <v-text-field
                    label="Name"                    
                    v-model="name"
                    prepend-icon="person"
                    type="text"
                  /> 

                  <v-text-field
                    label="Email"                   
                    v-model="email"
                    prepend-icon="email"
                    type="text"
                  />

                  <v-text-field
                    id="password"
                    label="password"                 
                    v-model="password"
                    prepend-icon="lock"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="indigo" dark @click="register">Save</v-btn>
              
                
              </v-card-actions>
            </v-card>


</template>  

<script>

import { EventBus } from '../EventBus';


export default {
    
  data:() => ({

    email:'',
    password:'',
    name:'',
    show:false
   
     
    }),


  methods: {

      clean(){

        this.$refs.registerform.reset();

      },

      register(){

    
        axios.post('/api/register',{name:this.name,email:this.email,password:this.password})
                    .then(response => {

                     localStorage.setItem('access_token', response.data.access_token);
                      
                      this.$auth.setUser(response.data.user);
                      EventBus.$emit("user_registered");

                     // this.$router.push('/calendar');
            
                    }).catch((error)=>{
                      this.clean();
                      this.$alertify.error(error.response.data.message);
                    });


      }
  },  
};


</script>       
