<template>
<div>
    <v-dialog v-model="openDialog" max-width="900px" scrollable>
        <v-card>
            <v-card-title>
            <span class="headline">Comentarios de la Solicitud Nro. {{inputItem.id}}</span>
            </v-card-title>
            <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12 md12>
                        <v-card>
                            <v-card-title>
                            <span class="headline"></span>
                            </v-card-title>
                            <v-card-text>
                                <v-textarea
                                    filled
                                    label="Agregue un Comentario"
                                    name="comentario"
                                    v-model="formParams.comentario"
                                    ></v-textarea>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="pink"  dark  @click.native="formParams.comentario=''">Limpiar</v-btn>
                            <v-btn color="green" dark  @click.native="comentar">Comentar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>

                    <template v-for="(item, index) in items">
                       <comentario :inputItem="item"></comentario>
                    </template>

                    <v-flex xs12 md12>

                    </v-flex>

                </v-layout>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue"  dark  @click.native="openDialog = false">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
</div>

</template>

<script>

import { EventBus } from "../../EventBus"
import Comentario from "./Comentario"

  export default {

    components:{
        'comentario':Comentario,
    },

    props:[
        'inputItem',
    ],

    data: () => ({

      items:[],
      openDialog: false,

      itemIndex: -1,
      actionOnItem:'',
      formParams: {},
    }),

    created(){


    },

     watch: {

      inputItem: function(val) {

        if (Object.keys(val).length){
          this.openDialog=true;
          this.fetchComentarios();
        }
      }
    },

    computed: {
        axiosParams() {
            const params = new URLSearchParams();
            params.append('solicitud_desarchivo_id', this.inputItem.id);
            return params;
        }
    },

    methods: {
      fetchComentarios(){
          axios.get('api/comentario', {
                params: this.axiosParams
            })
              .then(response=>{
                  this.items=response.data.data
                  console.log(this.items)
               })
              .catch((error)=>{
                    console.log(error);

              })
       },
       comentar(){
          if (this.formParams.comentario) {
              this.formParams.solicitud_desarchivo_id = this.inputItem.id
              axios.post('api/comentario',this.formParams)
                .then(response=>{

                    // EventBus.$emit("items_refreshed", response.data.data);
                    this.fetchComentarios();
                    this.$alertify.success("¡Se ha enviado el comentario!")
                    this.formParams.comentario = ''
                })
                .catch((error)=>{
                console.log(error);
            });
            }else{
             this.$alertify.message("Debe ingresar un comentario")
           }
        },
    }
  }
</script>
